import styled from '@emotion/styled'
import { HEADER_HEIGHT_VALUES } from '../constants'
import mq from '../styles/breakpoints'

export const SectionContainer = styled.main`
  ${mq({
    maxHeight: [
      `calc(100vh - ${HEADER_HEIGHT_VALUES.MOBILE}px)`,
      `calc(100vh - ${HEADER_HEIGHT_VALUES.TABLET}px)`,
      `calc(100vh - ${HEADER_HEIGHT_VALUES.DESKTOP}px)`,
    ],
    overflowY: 'scroll',
  })}
`
