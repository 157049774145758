import React from 'react'
import { GenericTableSection } from '../containers/GenericTableSection'

import OneBlock from '../containers/OneBlockSection'
import { BlogEmbedEntries } from './RichText'
import { OneLinkSection } from '../containers/OneLinkSection'

interface IProps {
  data: BlogEmbedEntries
}

export const BlogEmbedEntry: React.FC<IProps> = ({ data }) => {
  switch (data?.__typename) {
    case 'ContentfulGenericTable':
      return <GenericTableSection data={data} isBlogTreatment />
    case 'ContentfulOneBlock':
      return <OneBlock data={data} isBlogTreatment />
    case 'ContentfulOneLink':
      return <OneLinkSection data={data} isBlogTreatment />
    default:
      return null
  }
}
