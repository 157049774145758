import React from 'react'
import styled from '@emotion/styled'

import { COLORS } from '@borrowell/bw-styles'
import { IOneBlock } from '../graphql/sections/oneBlock'
import mq from '../styles/breakpoints'

import oneBlockBackground from '../images/oneblock-background.png'
import oneBlockBackgroundMobile from '../images/oneblock-background-mobile.png'

import { Heading, Paragraph, Superscript } from '../components/typography'
import { Button } from '../components/Button'
import AppStoreBadges from '../components/AppStoreBadges'
import Section from '../components/Section'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { getCookieData } from '../helpers/utils'

interface IProps {
  data: IOneBlock
  isBlogTreatment?: boolean
}

/**
 * Custom Styled-Components
 */

const BlogTreatmentContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
    borderRadius: '8px',
    flex: 1,
    alignSelf: 'center',
    alignItems: 'flex-start',
    padding: '24px',
    background: [
      `transparent url(${oneBlockBackgroundMobile}) no-repeat`,
      `transparent url(${oneBlockBackground}) no-repeat`,
      `transparent url(${oneBlockBackground}) no-repeat`,
    ],
    backgroundSize: ['cover', 'cover', 'cover'],
    margin: '0 auto',
  })}
`

/**
 * Custom Styles for External Components
 */

const customSectionStyles = {
  defaultStyle: {
    padding: ['100px 20px 120px 20px', '117px 36px', '100px 183px 102px 183px'],
    margin: '50px auto',
  },
  blogStyle: {
    padding: 0,
    margin: '12px auto 25px auto',
  },
}

const paragraphStyles = {
  hasBackground: {
    margin: ['25px 0 40px 0', '25px 0 50px 0', '20px 0 50px 0'],
    color: COLORS.WHITE,
  },
  noBackground: {
    margin: ['25px 0 40px 0', '25px 0 50px 0', '20px 0 50px 0'],
    color: COLORS.NEUTRAL.COOL['600'],
  },
  blogStyle: {
    margin: '0 0 16px 0',
    textAlign: 'left',
  },
}

const titleStyles = {
  hasBackground: {
    color: COLORS.WHITE,
  },
  noBackground: {
    color: COLORS.NEUTRAL.COOL['900'],
  },
  blogStyle: {
    margin: '0 0 8px 0',
    textAlign: 'left',
  },
}

const buttonStyles = {
  defaultStyles: { borderRadius: '30px' },
  blogStyle: {
    padding: '5px',
    fontSize: '14px',
    height: '36px',
    width: '208px',
  },
}

const buttonWrapperStyles = {
  blogStyle: { padding: 0 },
}

const DefaultOneBlock: React.FC<IProps> = ({ data }) => {
  const backgroundImageDesktopUrl =
    data.backgroundImageDesktop?.image.gatsbyImageData
  const backgroundImageTabletUrl =
    data.backgroundImageTablet?.image.gatsbyImageData
  const backgroundImageMobileUrl =
    data.backgroundImageMobile?.image.gatsbyImageData
  const buttonLink = data.oneBlock.link?.link
  const buttonText = data.oneBlock.link?.linkText
  const buttonName = data.oneBlock.link?.linkName
  const buttonRel = data.oneBlock.link?.rel
  const buttonContentfulId = data.oneBlock.link?.contentful_id ?? null
  const buttonTypeName = data.oneBlock.link?.__typename ?? null
  const linkCookieData = getCookieData(data.oneBlock.link)

  const sectionText = data.oneBlock.text?.text
  const {
    title: sectionTitle,
    showAppStoreDownloadButtons,
    oneBlock: { title },
  } = data

  const hasValidButton = buttonLink && buttonText && buttonName

  const hasBackgroundImage = !!(
    backgroundImageDesktopUrl &&
    backgroundImageTabletUrl &&
    backgroundImageMobileUrl
  )

  const background =
    hasBackgroundImage &&
    ([
      backgroundImageMobileUrl,
      backgroundImageTabletUrl,
      backgroundImageDesktopUrl,
    ] as IGatsbyImageData[])

  return (
    <Section
      title={sectionTitle}
      customStyles={customSectionStyles.defaultStyle}
      background={background ? { background } : undefined}
    >
      <Heading
        as="h3"
        styleOverrides={
          hasBackgroundImage
            ? titleStyles.hasBackground
            : titleStyles.noBackground
        }
      >
        <Superscript>{title}</Superscript>
      </Heading>
      <Paragraph
        styleOverrides={
          hasBackgroundImage
            ? paragraphStyles.hasBackground
            : paragraphStyles.noBackground
        }
      >
        <Superscript>{sectionText}</Superscript>
      </Paragraph>
      {showAppStoreDownloadButtons ? (
        <AppStoreBadges />
      ) : (
        hasValidButton && (
          <Button
            to={buttonLink}
            name={buttonName}
            rel={buttonRel}
            styleOverrides={buttonStyles.defaultStyles}
            linkCookieData={linkCookieData}
            contentfulId={buttonContentfulId}
            typeName={buttonTypeName}
          >
            {buttonText}
          </Button>
        )
      )}
    </Section>
  )
}

const BlogOneBlock: React.FC<IProps> = ({ data }) => {
  const buttonLink = data.oneBlock.link?.link
  const buttonText = data.oneBlock.link?.linkText
  const buttonName = data.oneBlock.link?.linkName
  const buttonRel = data.oneBlock.link?.rel
  const buttonContentfulId = data.oneBlock.link?.contentful_id ?? null
  const buttonTypeName = data.oneBlock.link?.__typename ?? null
  const sectionText = data.oneBlock.text?.text
  const linkCookieData = getCookieData(data.oneBlock.link)

  const {
    title: sectionTitle,
    showAppStoreDownloadButtons,
    oneBlock: { title },
  } = data

  return (
    <Section title={sectionTitle} customStyles={customSectionStyles.blogStyle}>
      <BlogTreatmentContainer>
        <Heading as="h5" styleOverrides={titleStyles.blogStyle}>
          {title}
        </Heading>
        <Paragraph styleOverrides={paragraphStyles.blogStyle}>
          {sectionText}
        </Paragraph>
        {showAppStoreDownloadButtons ? (
          <AppStoreBadges />
        ) : (
          buttonLink &&
          buttonName &&
          buttonText && (
            <Button
              to={buttonLink}
              name={buttonName}
              rel={buttonRel}
              styleOverrides={buttonStyles.blogStyle}
              style={buttonWrapperStyles.blogStyle}
              linkCookieData={linkCookieData}
              contentfulId={buttonContentfulId}
              typeName={buttonTypeName}
            >
              {buttonText}
            </Button>
          )
        )}
      </BlogTreatmentContainer>
    </Section>
  )
}

const OneBlockSection: React.FC<IProps> = ({ data, isBlogTreatment }) => {
  return isBlogTreatment ? (
    <BlogOneBlock data={data} />
  ) : (
    <DefaultOneBlock data={data} />
  )
}

export default OneBlockSection
