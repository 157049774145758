import React from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { useStaticQuery, graphql } from 'gatsby'

import mq from '../styles/breakpoints'

import { Button } from '../components/Button'
import { IOneLink } from '../graphql/sections/oneLink'
import { IFooter } from '../graphql/sections/footer'

interface IProps {
  data: { stickyCTA: IOneLink }
}

const Section = styled.div`
  ${mq({
    display: ['block', 'none', 'none'],
    width: '100%',
    position: 'sticky',
    bottom: '-25px',
    paddingTop: '100px',
    textAlign: 'center',
  })}
`

const stickyCTAStyles = {
  display: 'inline-block',
  width: '100%',
  padding: '0 0 15px 0',
}

const stickyCTACustomStyles = {
  display: 'block',
  width: '100%',
  borderRadius: '0',
}

export const StickyCTA: React.FC<IProps> = React.memo(
  ({
    data: {
      stickyCTA: { oneLink },
    },
  }) => {
    const stickyCTALink = oneLink.link
    const stickyCTAText = oneLink.linkText
    const stickyCTAName = oneLink.linkName
    const stickyCTARel = oneLink.rel
    const contentfulId = oneLink.contentful_id
    const typeName = oneLink.__typename

    const isActive = false
    // experiments &&
    // experiments.some(experiment => {
    //   return experiment.config && experiment.config.stickyCTA === 'true'
    // })

    return isActive ? (
      <Section>
        <Button
          to={stickyCTALink}
          name={stickyCTAName}
          rel={stickyCTARel}
          style={stickyCTAStyles}
          styleOverrides={stickyCTACustomStyles}
          contentfulId={contentfulId}
          typeName={typeName}
        >
          {stickyCTAText}
        </Button>
      </Section>
    ) : null
  },
)

export const StickyCTASection: React.FC = props => {
  const data = useStaticQuery<{ contentfulFooter: IFooter }>(graphql`
    query {
      contentfulFooter {
        ...FooterFragment
      }
    }
  `)

  return <StickyCTA data={data.contentfulFooter} />
}

export default StickyCTASection
