import React, { ElementType } from 'react'
import styled from '@emotion/styled'
import facepaint from 'facepaint'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import mq from '../styles/breakpoints'
import { formatSectionId } from '../helpers/utils'
import { useMediaQuery } from '../hooks/useMediaQuery'
import { useProgressiveLoad } from '../hooks/useProgressiveLoad'
import { IWithCustomStyles, IWithStyleOverrides } from './commonTypes'

interface IProps {
  customStyles?: facepaint.BaseArg
  title: string | null
  background?: ISectionWithBackgroundProps
  as?: ElementType
}

interface ISectionWithBackgroundProps extends IWithStyleOverrides {
  background: IGatsbyImageData | IGatsbyImageData[]
  isAboveFold?: boolean
  as?: ElementType
}

interface IBackground {
  background?: string | string[]
  isLoaded?: boolean
  isAboveFold?: boolean
}

const SectionWrapper = styled.section<IBackground & IWithStyleOverrides>(
  ({ background, isLoaded, styleOverrides, isAboveFold }) =>
    mq({
      width: '100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: background,
      opacity:
        typeof isLoaded === 'undefined' || isLoaded || isAboveFold ? 1 : 0,
      transition: 'opacity .25s linear',
      willChange: isLoaded === false ? 'opacity' : undefined,
      ...styleOverrides,
    })
)

const ContentWrapper = styled.div(({ customStyles }: IWithCustomStyles) =>
  mq({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    padding: ['35px 22px', '50px 36px', '50px 60px'],
    maxWidth: '1440px',
    ...customStyles,
  })
)

const AnchorLandingPosition = styled.div`
  ${mq({
    position: 'relative',
    top: ['-90px', '', '-200px'],
    visibility: 'hidden',
    pointerEvents: 'none',
  })}
`

const SectionWithBackground: React.FC<ISectionWithBackgroundProps> = ({
  background,
  isAboveFold,
  styleOverrides,
  children,
  as,
}) => {
  const mediaQueries = [
    useMediaQuery('mobile'),
    useMediaQuery('tablet'),
    useMediaQuery('desktop'),
  ]
  const {
    setIntersectionElementRef,
    isImageLoaded,
    imgSrc,
  } = useProgressiveLoad(
    Array.isArray(background)
      ? background[mediaQueries.indexOf(true)]
      : background,
    { shouldLoadImmediately: isAboveFold }
  )
  return (
    <SectionWrapper
      ref={ref => setIntersectionElementRef(ref)}
      background={`url(${imgSrc})`}
      isLoaded={isImageLoaded}
      styleOverrides={styleOverrides}
      isAboveFold={isAboveFold}
      as={as}
    >
      {children}
    </SectionWrapper>
  )
}

const getWebPSrc = (srcProps: { srcSet: string }) => {
  // srcSet is a string of srcs seperated with ,\n and at the end there is a ' 1440'
  const srcs = srcProps.srcSet.split(' ')[0].split(',\n')
  return srcs[-1]
}

export const Section: React.FC<IProps> = ({
  title,
  children,
  customStyles,
  background,
  as,
}) => {
  const sectionId = formatSectionId(title)

  const content = (
    <>
      {sectionId && <AnchorLandingPosition id={sectionId} />}
      <ContentWrapper customStyles={customStyles}>{children}</ContentWrapper>
    </>
  )

  if (background && background.isAboveFold) {
    const mqBackground = Array.isArray(background.background)
      ? background.background.map(bg => {
          const webpImg =
            bg.images.sources?.[0] && getWebPSrc(bg.images.sources[0])
          return `${webpImg ? `url('${webpImg}'),` : ''}url('${bg.images
            .fallback?.src ?? ''}')`
        })
      : `url(${background.background.images.sources?.[0] &&
          getWebPSrc(background.background.images.sources[0])}),url(${
          background.background.images.fallback?.src
        })`
    return (
      <SectionWrapper
        background={mqBackground}
        isAboveFold={true}
        isLoaded={true}
        as={as}
      >
        {content}
      </SectionWrapper>
    )
  }
  if (background) {
    return (
      <SectionWithBackground as={as} {...background}>
        {content}
      </SectionWithBackground>
    )
  }
  return <SectionWrapper as={as}>{content}</SectionWrapper>
}

export default Section
