import { useState, useEffect, useCallback, useRef, Dispatch, SetStateAction } from 'react'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import { getIsSsg } from '../helpers/utils'

interface IConfig {
  shouldLoadImmediately?: boolean
}

interface IUseProgressiveLoad {
  isImageLoaded: boolean
  imgSrc: string
  setIntersectionElementRef: Dispatch<SetStateAction<HTMLElement | null>>
}

// 1x1 transparent image, smallest possible transparent image dataURI
// This should never actually happen, but in an emergency prevents complete failure
// also, prevents typescript from moaning
const API_FAIL_FAILBACK = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

export const useProgressiveLoad = (imageData: IGatsbyImageData, config: IConfig = {}): IUseProgressiveLoad => {
  const { shouldLoadImmediately } = config

  const finalImgSrc = imageData?.images.fallback?.src ?? API_FAIL_FAILBACK
  const initialImgSrc = imageData?.placeholder?.fallback ?? API_FAIL_FAILBACK

  const isSsg = getIsSsg()
  const [imgSrc, setImgSrc] = useState(initialImgSrc)
  const [intersectionElementRef, setIntersectionElementRef] = useState<HTMLElement | null>(null)
  const [isImageLoaded, setIsImageLoaded] = useState(isSsg ? true : false)
  const [shouldStartLoad, setShouldStartLoad] = useState(shouldLoadImmediately === true)

  const imageLoader = useRef<HTMLImageElement>()

  const intersectionCallback: IntersectionObserverCallback = useCallback(
    ([entry]) => {
      if (!shouldStartLoad) {
        if (entry.isIntersecting) setShouldStartLoad(true)
      }
    },
    [shouldStartLoad],
  )

  useEffect(() => {
    // intended background has changed (most likely due to change in breakpoint)
    if (isImageLoaded && finalImgSrc !== imgSrc) {
      setImgSrc(finalImgSrc)
    } else if (!isImageLoaded && initialImgSrc !== imgSrc) {
      setImgSrc(initialImgSrc)
    }
  }, [finalImgSrc, initialImgSrc, imgSrc, isImageLoaded, isSsg])

  useEffect(() => {
    if (shouldStartLoad && !isImageLoaded) {
      imageLoader.current = new Image()
      imageLoader.current.src = finalImgSrc
      imageLoader.current.onload = () => {
        setIsImageLoaded(true)
        if (finalImgSrc !== imgSrc) setImgSrc(finalImgSrc)
      }
    }
  }, [shouldStartLoad, isImageLoaded, finalImgSrc, imgSrc])

  useEffect(() => {
    let observer: IntersectionObserver
    if (intersectionElementRef && !shouldStartLoad) {
      observer = new IntersectionObserver(intersectionCallback, {
        root: null,
        rootMargin: '200px',
        threshold: 0.01,
      })
      observer.observe(intersectionElementRef)
    }
    return () => {
      if (intersectionElementRef && observer) observer.unobserve(intersectionElementRef)
    }
  }, [intersectionElementRef, shouldStartLoad, intersectionCallback])

  return { isImageLoaded, imgSrc, setIntersectionElementRef }
}
