import React from 'react'
import { Helmet } from 'react-helmet-async'

import { METADATA } from '../constants'
import { useSEOData } from '../context/useSEO'
import {
  formatArticleStructuredData,
  formatFaqStructuredData,
} from '../helpers/formatStructuredData'
import { IMetadata } from '../graphql/sections/metadata'

interface IProps {
  data: IMetadata
}

export const SEO: React.FC<IProps> = ({
  data: {
    title = METADATA.BORROWELL_TITLE,
    author = METADATA.BORROWELL_AUTHOR,
    language = METADATA.BORROWELL_LANGUAGE,
    image,
    keywords: keywordsCopy,
    slug,
    canonicalLink,
    description: descriptionCopy,
  },
}) => {
  const imageApiUrl = image?.image.file.url ?? METADATA.BORROWELL_IMAGE
  const imageUrl =
    imageApiUrl.indexOf('https:') > -1 ? imageApiUrl : `https:${imageApiUrl}`
  const keywords = keywordsCopy?.keywords ?? METADATA.BORROWELL_KEYWORDS
  const computedCanonicalLink = canonicalLink
    ? canonicalLink
    : `${METADATA.BORROWELL_URL}${slug || ''}`
  const description =
    descriptionCopy?.description ?? METADATA.BORROWELL_DESCRIPTION

  const { faqData, articleData } = useSEOData()
  const formattedFaqData = React.useMemo(
    () => faqData && formatFaqStructuredData(faqData),
    [faqData]
  )

  const formattedArticleData = React.useMemo(
    () => articleData && formatArticleStructuredData(articleData),
    [articleData]
  )

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang: language,
      }}
    >
      <title>{`${title} | ${METADATA.BORROWELL_TITLE}`}</title>
      <meta name="description" content={description} />

      {/* Facebook */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imageUrl} />

      {/* Facebook */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />

      {/* Keywords */}
      <meta name="keywords" content={keywords} />

      {/* Canonical Links */}
      <link rel="canonical" href={computedCanonicalLink} />

      {/* Structured data */}
      {formattedFaqData && (
        <script type="application/ld+json">{formattedFaqData}</script>
      )}
      {formattedArticleData && (
        <script type="application/ld+json">{formattedArticleData}</script>
      )}
    </Helmet>
  )
}
