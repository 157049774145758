import { useState, useEffect } from 'react'
import { mediaQueries } from '../styles/breakpoints'

type MqSize = 'mobile' | 'tablet' | 'desktop'
const QUERIES_LOOKUP = {
  mobile: mediaQueries[0],
  tablet: mediaQueries[1],
  desktop: mediaQueries[2],
} as const

export const useMediaQuery = (size: MqSize): boolean => {
  const [matches, setMatches] = useState(false)
  const query = QUERIES_LOOKUP[size]

  useEffect(() => {
    if (typeof window !== 'undefined' && window.matchMedia) {
      const media = window.matchMedia(query)
      if (media.matches !== matches) {
        setMatches(media.matches)
      }
      const listener = () => {
        setMatches(media.matches)
      }
      media.addEventListener('change', listener)
      return () => media.removeEventListener('change', listener)
    }
  }, [matches, query])

  return matches
}
