import React from 'react'

import { Button } from '../components/Button'
import Section from '../components/Section'
import { IOneLink } from '../graphql/sections/oneLink'
import { getCookieData } from '../helpers/utils'

interface IProps {
  data: IOneLink
  isBlogTreatment?: boolean
}

const buttonStyles = {
  borderRadius: ['30px', '', '30px'],
  fontSize: ['18px', '', '18px'],
  fontWeight: ['900', '', '900'],
  lineHeight: ['1.44', '', '1.44'],
}

const blogButtonStyles = {
  ...buttonStyles,
  fontSize: ['14px', '', '18px'],
  padding: '0 40px',
  height: ['40px', '55px', '55px'],
  maxWidth: '100%',
}

export const OneLinkSection: React.FC<IProps> = ({
  data: {
    oneLink,
    title: sectionTitle,
    backgroundImageDesktop,
    backgroundImageMobile,
    backgroundImageTablet,
  },
  isBlogTreatment,
}) => {
  const backgroundImageDesktopUrl = backgroundImageDesktop?.image.file.url
  const backgroundImageTabletUrl = backgroundImageTablet?.image.file.url
  const backgroundImageMobileUrl = backgroundImageMobile?.image.file.url
  const buttonLink = oneLink.link
  const buttonText = oneLink.linkText
  const buttonName = oneLink.linkName
  const buttonRel = oneLink.rel
  const buttonContentfulId = oneLink.contentful_id
  const buttonTypeName = oneLink.__typename

  const linkCookieData = getCookieData(oneLink)

  const hasBackground =
    backgroundImageDesktopUrl &&
    backgroundImageTabletUrl &&
    backgroundImageMobileUrl

  const customStyles = hasBackground
    ? {
        borderRadius: ['0', '0', '30px'],
        backgroundPosition: 'center',
        backgroundImage: [
          `url(${backgroundImageMobileUrl})`,
          `url(${backgroundImageTabletUrl})`,
          `url(${backgroundImageDesktopUrl})`,
        ],
      }
    : undefined
  const styles = isBlogTreatment ? blogButtonStyles : buttonStyles

  return (
    <Section title={sectionTitle} customStyles={customStyles}>
      <Button
        to={buttonLink}
        rel={buttonRel}
        name={buttonName}
        styleOverrides={styles}
        linkCookieData={linkCookieData}
        contentfulId={buttonContentfulId}
        typeName={buttonTypeName}
      >
        {buttonText}
      </Button>
    </Section>
  )
}
