import { IArticleData, IFAQData, IPerson } from '../context/useSEO'

interface IFaqStructuredData {
  '@context': 'https://schema.org'
  '@type': 'FAQPage'
  mainEntity: {
    '@type': 'Question'
    name: string
    acceptedAnswer: {
      '@type': 'Answer'
      text: string
    }
  }[]
}

interface IArticleStructuredData {
  '@context': 'https://schema.org'
  '@type': 'NewsArticle'
  headline: string
  image: string | string[]
  datePublished?: string
  dateModified?: string
  author?: IPersonStructuredData | IPersonStructuredData[]
}

interface IPersonStructuredData extends IPerson {
  '@context': 'https://schema.org'
  '@type': 'Person'
  name: string
  jobTitle?: string
  description?: string
  url?: string
}

export function formatFaqStructuredData(data: IFAQData): string {
  const result: IFaqStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [],
  }
  for (const item in data) {
    result.mainEntity.push({
      '@type': 'Question',
      name: item,
      acceptedAnswer: {
        '@type': 'Answer',
        text: data[item],
      },
    })
  }
  return JSON.stringify(result)
}

export function formatAuthorStructuredData(
  data: IPerson | IPerson[]
): IPersonStructuredData | IPersonStructuredData[] {
  const formatter = (author: IPerson): IPersonStructuredData => ({
    '@context': 'https://schema.org',
    '@type': 'Person',
    ...author,
  })
  if (Array.isArray(data)) {
    return data.map(formatter)
  }
  return formatter(data)
}

export function formatArticleStructuredData(data: IArticleData): string {
  const result: IArticleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    ...data,
    ...('author' in data
      ? {
          author: formatAuthorStructuredData(
            data.author as IPerson | IPerson[]
          ),
        }
      : { author: undefined }),
  }

  return JSON.stringify(result)
}
