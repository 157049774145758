import React from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { graphql, useStaticQuery } from 'gatsby'

import mq from '../styles/breakpoints'
import { COLORS } from '@borrowell/bw-styles'

import { Hr } from '../components/Hr'
import { Heading, Paragraph, ListElement, List } from '../components/typography'
import { RichText } from '../components/RichText'
import { Link } from '../components/Link'
import { Image } from '../components/Image'
import { Section } from '../components/Section'

import { IFooter } from '../graphql/sections/footer'
import { ILink } from '../graphql/modules/link'
import { THEME } from '../constants/enums'
import { ISocial } from '../graphql/modules/social'
import { useSendLinkClickedEvent } from '../hooks/analytics/useSendLinkClickedEvent'
import { paramsService } from '../services/paramsService'

interface IFooterQuery {
  contentfulFooter: IFooter
}

/**
 * Custom Styled-Components
 */

const Content = styled.div`
  ${mq({
    flex: 1,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    maxWidth: '1440px',
    width: '100%',
  })}
`

const LinksSection = styled.div`
  ${mq({
    display: 'flex',
    width: '100%',
    flexDirection: ['column', 'column', 'row'],
    alignItems: ['center', 'center', 'start'],
    justifyContent: 'space-around',
    padding: ['55px 0 0 0', '55px 0 0 0', '60px 72px 0 72px'],
  })}
`
const SocialContainer = styled.ul`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'row', 'column'],
    marginLeft: '0',
    marginBlockStart: '0',
    marginBlockEnd: '0',
    alignItems: 'center',
    justifyContent: ['', 'center', ''],
    padding: ['0 20px', '0 36px 40px 36px', ''],
    flexWrap: 'wrap',
    width: ['100%', '100%', 'auto'],
  })}
`
const SocialsLinks = styled.li`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    padding: ['19px 0 10px 0', '', '0'],
    flex: '2',
    alignSelf: ['stretch'],
    justifyContent: 'space-between',
    margin: 'auto',
    listStyle: 'none',
    maxWidth: '300px',
    marginBottom: '0',
  })}
`
const StoreBadges = styled.li`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    padding: ['0 0 10px 0', '0', '10px 0 15px 0'],
    flex: '3',
    justifyContent: ['center', 'center', 'center'],
    margin: 0,
    listStyle: 'none',
  })}
`

const LinksContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: ['wrap', 'nowrap', 'nowrap'],
    justifyContent: ['space-between', '', 'space-evenly'],
    maxWidth: ['100%', '100%', '60%'],
    marginBottom: ['0', '10px', '20px'],
    padding: '0 20px',
    flex: 1,
  })}
`
const LinksColumnContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'start',
    margin: ['0 10px 20px 10px', '0px 20px', '0 auto'],
    padding: ['', '', '0 4px'],
  })}
`
const DisclaimerSection = styled.div`
  ${mq({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: ['15px 20px', '30px 36px 14px 36px', '20px 72px'],
  })}
`
const CopyRightSection = styled.div`
  ${mq({
    margin: 'auto',
    padding: ['13px 20px 13px 20px', '13px 36px 16px 36px', '13px 72px'],
  })}
`

const sectionStyles = {
  padding: 0,
}

/**
 * Custom Styles for External Components
 */

const customHrStyles = {
  margin: '0',
  backgroundColor: COLORS.WHITE,
  opacity: 0.2,
}
const customSocialHrStyles = {
  ...customHrStyles,
  display: ['', '', 'none'],
}

const socialImageStyles = {
  width: '48px',
  height: '48px',
}

const badgeImageStyles = {
  width: ['150px', '177px', '150px'],
  height: ['50px', '60px', '43px'],
}

const richTextParagraphStyles = {
  color: COLORS.WHITE,
  fontSize: '12px',
  fontHeight: '1.5',
  fontWeight: '400',
  margin: '0',
  maxWidth: 'none',
}

const h5Styles = {
  fontSize: '18px',
  alignSelf: 'start',
  lineHeight: '1.44',
  color: COLORS.WHITE,
  marginBottom: ['10px', '10px', '27px'],
  marginTop: ['20px', '20px', '29px'],
  padding: '0',
  whiteSpace: 'nowrap',
}

const linkStyles = {
  display: 'flex',
  height: ['48px', '48px', '21px'],
  margin: ['', '', '4px 0'],
  whiteSpace: 'nowrap',
}

const linkItemStyles = {
  height: ['48px', '48px', '26px'],
  '*:last-child': {
    margin: '4px 0',
    marginBottom: '4px',
    height: 'fit-content',
  },
}

const customListStyles = {
  listStyle: 'none',
  margin: 0,
  'li:last-of-type': {
    marginBottom: 0,
  },
}

const LinksList: React.FC<{ title: string; linkList: ILink[] }> = ({ title, linkList = [] }) => (
  <LinksColumnContainer>
    <Heading as="h2" styledAs="h5" styleOverrides={h5Styles}>
      {title}
    </Heading>
    <List ordered={false} styleOverrides={customListStyles}>
      {linkList.map((linkListItem, index) => {
        const link = get(linkListItem, 'link', '')
        const name = get(linkListItem, 'linkName', '')
        const linkText = get(linkListItem, 'linkText', '')
        const altText = get(linkListItem, 'alternateText', '')
        const rel = get(linkListItem, 'rel', '')
        const contentfulId = get(linkListItem, 'contentful_id', '')
        const typeName = get(linkListItem, '__typename', '')
        return (
          <ListElement styleOverrides={linkItemStyles} key={linkListItem.contentful_id}>
            <Link
              key={`${index}-${altText}`}
              name={name}
              to={link}
              text={linkText}
              rel={rel}
              theme={THEME.SECONDARY_THEME}
              textStyles={{
                display: 'flex',
                alignSelf: 'center',
                whiteSpace: 'nowrap',
              }}
              styleOverrides={linkStyles}
              contentfulId={contentfulId}
              typeName={typeName}
            />
          </ListElement>
        )
      })}
    </List>
  </LinksColumnContainer>
)
const SocialLinksList: React.FC<{ socialLinks: ISocial[] }> = ({ socialLinks }) => (
  <SocialsLinks>
    {socialLinks.map(({ image, link, title, contentful_id, __typename }) => {
      const fluidImg = image?.image.gatsbyImageData
      const altText = image?.alternateText.alternateText
      const platformLink = link.link
      const platformLinkName = link.linkName

      const sendLinkClickedEvent = useSendLinkClickedEvent()

      const handleClick = () => {
        sendLinkClickedEvent({
          entry_id: contentful_id,
          contentful_type: __typename,
          link_to: platformLink,
          link_text: link.linkText ?? 'N/A',
        })
      }

      return (
        <a
          href={platformLink}
          rel="noopener noreferrer"
          target="_blank"
          id={title}
          key={`${platformLink}-${platformLinkName}`}
          aria-label={altText}
          data-entry-id={contentful_id}
          onClick={() => handleClick()}
        >
          <Image fluid={fluidImg} alt="" customStyles={socialImageStyles} />
        </a>
      )
    })}
  </SocialsLinks>
)

export const Footer: React.FC<{ data: IFooter }> = React.memo(
  ({
    data: {
      backgroundImageMobile,
      backgroundImageTablet,
      backgroundImageDesktop,
      social,
      productTitle,
      productsList,
      aboutTitle,
      about: aboutLinkList,
      notableLinksTitle,
      notableLinks: notableLinkList,
      appStore,
      playStore,
      appStoreBadge,
      googlePlayBadge,
      disclaimerText: disclaimerTextJson,
      allRightsReserved: copyrightMessage,
      contentful_id,
      __typename,
    },
  }) => {
    const background = [
      backgroundImageMobile.image.gatsbyImageData,
      backgroundImageTablet.image.gatsbyImageData,
      backgroundImageDesktop.image.gatsbyImageData,
    ]

    const [appStoreLinks, setAppStoreLinks] = React.useState({
      appStoreLink: appStore.link,
      googlePlayLink: playStore.link,
    })

    const { appStoreLink, googlePlayLink } = appStoreLinks

    React.useEffect(() => {
      if (appStoreLink.includes('{{mktg_site_session_id}}') || googlePlayLink.includes('{{mktg_site_session_id}}')) {
        const formattedAppStoreLink = paramsService.formatUrlWithMarketingSiteSessionId(appStoreLink)
        const formattedGooglePlayLink = paramsService.formatUrlWithMarketingSiteSessionId(googlePlayLink)
        setAppStoreLinks(() => ({ appStoreLink: formattedAppStoreLink, googlePlayLink: formattedGooglePlayLink }))
      }
    }, [appStoreLink, googlePlayLink])

    const appStoreAltText = appStore.alternateText
    const googlePlayAltText = playStore.alternateText
    const appStoreBadgeFluidImg = appStoreBadge.image.gatsbyImageData
    const googlePlayBadgeFluidImg = googlePlayBadge.image.gatsbyImageData
    const copyrightText = `2014-${new Date().getFullYear()} Borrowell® | ${copyrightMessage}`

    const sendLinkClickedEvent = useSendLinkClickedEvent()

    const handleClick = (storeDetails: any, link: any) => {
      sendLinkClickedEvent({
        entry_id: storeDetails.contentful_id,
        contentful_type: storeDetails.__typename,
        link_to: link,
        link_text: storeDetails.linkText ?? 'N/A',
      })
    }

    return (
      <Section title="footer section" as="footer" customStyles={sectionStyles} background={{ background }}>
        <Content>
          <LinksSection>
            <SocialContainer>
              <StoreBadges>
                <a
                  href={appStoreLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={appStoreAltText}
                  data-entry-id={appStore.contentful_id}
                  onClick={() => handleClick(appStore, appStoreLink)}
                >
                  <Image fluid={appStoreBadgeFluidImg} alt={appStoreAltText} customStyles={badgeImageStyles} />
                </a>
                <a
                  href={googlePlayLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={googlePlayAltText}
                  data-entry-id={playStore.contentful_id}
                  onClick={() => handleClick(playStore, googlePlayLink)}
                >
                  <Image fluid={googlePlayBadgeFluidImg} alt={googlePlayAltText} customStyles={badgeImageStyles} />
                </a>
              </StoreBadges>
              <SocialLinksList socialLinks={social} />
            </SocialContainer>
            <Hr styleOverrides={customSocialHrStyles} />
            <LinksContainer>
              <LinksList title={productTitle} linkList={productsList} />
              <LinksList title={aboutTitle} linkList={aboutLinkList} />
              <LinksList title={notableLinksTitle} linkList={notableLinkList} />
            </LinksContainer>
          </LinksSection>

          <DisclaimerSection>
            {disclaimerTextJson && (
              <RichText content={disclaimerTextJson} customStyles={{ paragraph: richTextParagraphStyles }} />
            )}
          </DisclaimerSection>

          <Hr styleOverrides={customHrStyles} />

          <CopyRightSection>
            <Paragraph styleOverrides={richTextParagraphStyles}>{copyrightText}</Paragraph>
          </CopyRightSection>
        </Content>
      </Section>
    )
  },
)

export const FooterSection: React.FC = () => {
  const data = useStaticQuery<IFooterQuery>(graphql`
    query FooterQuery {
      contentfulFooter {
        ...FooterFragment
      }
    }
  `)

  return <Footer data={data.contentfulFooter} />
}

export default FooterSection
