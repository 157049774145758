import React, { useMemo } from 'react'
import styled from '@emotion/styled'

import { COLORS } from '@borrowell/bw-styles'
import { mq } from '../styles'

import { Heading } from '../components/typography'

import { IContentfulGenericTable } from '../graphql/modules/contentfulGenericTable'

interface IProps {
  data: IContentfulGenericTable
  isBlogTreatment?: boolean
}

const Table = styled.table`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
    borderCollapse: 'separate',
    borderSpacing: 0,
    borderRadius: '8px',
    width: '100%',
  })}
`

const THead = styled.thead`
  ${mq({
    display: ['none', 'flex', 'flex'],
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    border: 'none',
    backgroundColor: COLORS.NEUTRAL.COOL['100'],
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  })}
`

const TBody = styled.tbody`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  })}
`

const commonCellStyles = {
  display: 'flex',
  textAlign: 'left',
  fontFamily: 'lato',
  fontWeight: '400',
  fontSize: '18px',
  justifySelf: 'stretch',
  alignItems: 'flex-start',
  justifyContent: ['flex-start', 'flex-start', 'flex-start'],
  flex: ['', 1, 1],
  padding: '20px',
}
const Th = styled.th<{ shouldRoundCorners?: boolean }>(
  ({ shouldRoundCorners }) =>
    mq({
      ...commonCellStyles,
      border: 'none',
      borderBottom: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
      fontWeight: '700',
      fontSize: '19px',
      borderRadius: '0',
      justifyContent: ['center', 'flex-start', 'flex-start'],
      textAlign: ['center', 'left', 'left'],
      padding: ['13px', '10px', '10px'],
      backgroundColor: [
        COLORS.NEUTRAL.COOL['100'],
        'transparent',
        'transparent',
      ],
      ':first-of-type': {
        paddingLeft: '10px',
        borderTopLeftRadius: [
          `${shouldRoundCorners ? '8px' : ''}`,
          '8px',
          '8px',
        ],
      },
      ':last-of-type': {
        paddingRight: '10px',
        borderTopRightRadius: [
          `${shouldRoundCorners ? '8px' : ''}`,
          '8px',
          '8px',
        ],
      },
    })
)

const TrDesktopTablet = styled.tr`
  ${mq({
    display: ['none', 'flex', 'flex'],
    flexBasis: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:nth-last-of-type(2) > td': {
      border: 'none',
    },
  })}
`

const TrMobile = styled.tr`
  ${mq({
    display: ['flex', 'none', 'none'],
    flexDirection: ['column', 'row'],
    flexBasis: '100%',
    ':last-of-type > td': {
      borderBottom: 'none',
    },
    '&:first-of-type > th': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
  })}
`

const Td = styled.td`
  ${mq({
    ...commonCellStyles,
    borderBottom: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
    ':first-of-type': {
      paddingLeft: ['', '13px', '13px'],
      borderBottom: [
        'none',
        `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
        `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
      ],
    },
    ':last-of-type': {
      paddingRight: ['13px', '10px', '10px'],
    },
    '& > div': {
      padding: '13px',
      maxWidth: '50%',
      ':first-of-type': {
        fontWeight: '700',
        paddingRight: '20px',
      },
    },
  })}
`

const titleStyles = {
  margin: '0 0 24px 0',
}

export const GenericTableSection: React.FC<IProps> = ({ data }) =>
  useMemo(() => {
    const { displayTitle, tableHead, tableRows, seperator } = data
    return (
      <>
        {!!displayTitle && (
          <Heading as="h3" styleOverrides={titleStyles}>
            {displayTitle}
          </Heading>
        )}
        <Table>
          <THead>
            <TrDesktopTablet>
              {tableHead.cells.map((cell, index) => {
                return <Th key={`head-${index}`}>{cell}</Th>
              })}
            </TrDesktopTablet>
          </THead>
          <TBody>
            {tableRows.map(
              ({ contentful_id: contentfulId, cells }, rowIndex) => {
                return (
                  <React.Fragment key={contentfulId}>
                    <TrDesktopTablet>
                      {cells.map((cell, cellIndex) => {
                        return (
                          <Td key={`row-${rowIndex}-cell-${cellIndex}`}>
                            {cell}
                          </Td>
                        )
                      })}
                    </TrDesktopTablet>
                    <TrMobile>
                      {cells.map((cell, cellIndex) => {
                        if (cellIndex === 0)
                          return (
                            <Th
                              key={`row-${rowIndex}-cell-${cellIndex}`}
                              shouldRoundCorners={rowIndex === 0}
                            >
                              {`${tableHead.cells[cellIndex] ||
                                ''}${seperator || ''} ${cell}`}
                            </Th>
                          )
                        return (
                          <Td key={`row-${rowIndex}-cell-${cellIndex}`}>
                            <div>{tableHead.cells[cellIndex] || ''}</div>
                            <div>{cell}</div>
                          </Td>
                        )
                      })}
                    </TrMobile>
                  </React.Fragment>
                )
              }
            )}
          </TBody>
        </Table>
      </>
    )
  }, [data])
