import React from 'react'
import styled from '@emotion/styled'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Options } from '@contentful/rich-text-react-renderer'

import mq from '../styles/breakpoints'
import {
  ITableOfContentsItem,
  useTableOfContentsFunctions,
} from '../context/TableOfContentsProvider'
import { formatSectionId } from '../helpers/utils'

// Components
import {
  Heading,
  Paragraph,
  Superscript,
  List,
  ListElement,
} from './typography'
import { Hr } from './Hr'
import { Link } from './Link'
import { Image } from './Image'
import { BlogEmbedEntry } from './BlogEmbedEntry'

// Types and interfaces
import {
  BLOCKS,
  Inline,
  MARKS,
  Mark,
  Text,
  INLINES,
  Block,
} from '@contentful/rich-text-types'
import facepaint from 'facepaint'
import { IContentfulAsset } from '../graphql/modules/contentfulAsset'
import { IContentfulGenericTable } from '../graphql/modules/contentfulGenericTable'
import { ILink } from '../graphql/modules/link'
import { IOneBlock } from '../graphql/sections/oneBlock'
import { IOneLink } from '../graphql/sections/oneLink'

const AnchorLandingPosition = styled.div`
  ${mq({
    position: 'relative',
    top: ['-90px', '', '-200px'],
    visibility: 'hidden',
    pointerEvents: 'none',
  })}
`

export type BlogEmbedAsset = IContentfulAsset
export type BlogEmbedEntries = IOneBlock | IContentfulGenericTable | IOneLink
export type BlogEmbedInlineEntries = ILink

export type RichTextData = {
  raw: string
  references?: (BlogEmbedEntries | BlogEmbedAsset | BlogEmbedInlineEntries)[]
}

interface IProps {
  content: RichTextData
  customStyles: {
    [element: string]: facepaint.BaseArg
  }
}

interface IMarkedText {
  mark: Mark
}

interface IHyperlinkNode extends Inline {
  nodeType: INLINES.HYPERLINK
  content: Text[]
  data: {
    uri: string
  }
}

interface IHyperlink {
  node: IHyperlinkNode
  isItalicized: boolean
}

interface IH3Node {
  node: Block
  tableOfContentsFunctions?: (item: ITableOfContentsItem) => void
  customStyles: facepaint.BaseArg
}

/******************************************
 * INTERNAL COMPONENTS
 *******************************************/

const MarkedText: React.FC<IMarkedText> = ({ mark, children }) => {
  const { type: markType } = mark
  const addSuperscript = <Superscript>{children}</Superscript>

  switch (markType) {
    case 'italic':
      return <i>{addSuperscript}</i>
    case 'bold':
      return <b>{addSuperscript}</b>
    case 'underline':
      return <u>{addSuperscript}</u>
    default:
      return addSuperscript
  }
}

const HyperLinkNode: React.FC<IHyperlink> = ({ node, isItalicized }) => {
  const {
    data: { uri },
    content,
  } = node
  const [href, paramString] = uri.split('?')

  const linkValue = (
    <>
      {content.map(({ value }, index) => {
        return <Superscript key={index}>{value}</Superscript>
      })}
    </>
  )

  const linkStyles = {
    ...(isItalicized && { fontStyle: 'italic' }),
  }

  const params = new URLSearchParams(paramString)
  const isNofollow = params.has('nofollow')
  params.delete('nofollow')
  const finalParamString = params.toString()

  const url = finalParamString ? `${href}?${finalParamString}` : href
  const rel = isNofollow ? 'nofollow' : undefined

  return (
    <Link
      key={url}
      to={url}
      rel={rel}
      textStyles={linkStyles}
      // TODO: Add value for contentfulId and typeName
      contentfulId="N/A"
      typeName="N/A"
    >
      {linkValue}
    </Link>
  )
}

const ParagraphContent: React.FC<{ nodes: (Inline | Text)[] }> = ({
  nodes,
}) => {
  return (
    <>
      {nodes.map((node, index) => {
        if (node.nodeType === 'text') {
          const { value, marks } = node
          return (
            <React.Fragment key={index}>
              {marks.reduce((accumulator, mark) => {
                return <MarkedText mark={mark}>{accumulator}</MarkedText>
              }, value as React.ReactNode)}
            </React.Fragment>
          )
        }

        // Hyperlink
        if (node.nodeType === 'hyperlink') {
          const isItalicized = !!node.content.find(
            contentNode =>
              contentNode.nodeType === 'text' &&
              contentNode.marks.find(mark => mark.type === MARKS.ITALIC),
          )

          return (
            <HyperLinkNode
              node={node as IHyperlinkNode}
              isItalicized={isItalicized}
              key={index}
            />
          )
        }

        if (node.nodeType === 'embedded-entry-inline') {
          const {
            data: {
              target: { link, rel, linkText, contentful_id, __typename },
            },
          } = node
          return (
            <Link
              key={index}
              to={link}
              rel={rel}
              contentfulId={contentful_id}
              typeName={__typename}
            >
              {linkText}
            </Link>
          )
        }

        // Any other inline node type is not currently supported
        return null
      })}
    </>
  )
}

const H3Node: React.FC<IH3Node> = ({
  node,
  tableOfContentsFunctions,
  customStyles,
  children,
}) => {
  const nodeText = node.content.reduce((acc, curr) => {
    if ('value' in curr) return (acc += curr.value)
    return acc
  }, '')
  const nodeAnchor = formatSectionId(nodeText)

  React.useEffect(() => {
    if (tableOfContentsFunctions && nodeAnchor) {
      tableOfContentsFunctions({ anchor: nodeAnchor, label: nodeText })
    }
  }, [nodeAnchor, nodeText, tableOfContentsFunctions])

  return (
    <Heading as="h3" styleOverrides={customStyles}>
      {nodeAnchor && (
        <AnchorLandingPosition
          id={nodeAnchor}
          data-cy={`heading3-${nodeAnchor}`}
        />
      )}
      {children}
    </Heading>
  )
}

const customImageStyles = {
  width: '100%',
  height: undefined,
  marginBottom: '30px',
}

export const RichText: React.FC<IProps> = ({ customStyles, content }) => {
  const tableOfContentsFunctions = useTableOfContentsFunctions()

  const options: Options = React.useMemo(
    () => ({
      renderMark: {
        [MARKS.BOLD]: text => <b>{text}</b>,
        [MARKS.ITALIC]: text => <i>{text}</i>,
        [MARKS.UNDERLINE]: text => <u>{text}</u>,
        [MARKS.CODE]: text => <pre>{text}</pre>,
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <Paragraph styleOverrides={customStyles?.[BLOCKS.PARAGRAPH]}>
            {'content' in node ? (
              // see https://github.com/contentful/rich-text/blob/master/packages/rich-text-types/src/schemaConstraints.ts#L47
              // for why it is safe to cast this type (Blocks as children of Blocks works on a whitelist basis)
              <ParagraphContent nodes={node.content as (Inline | Text)[]} />
            ) : (
              children
            )}
          </Paragraph>
        ),
        [BLOCKS.HEADING_1]: (node, children) => (
          <Heading styleOverrides={customStyles?.[BLOCKS.HEADING_1]}>
            {children}
          </Heading>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <Heading as="h2" styleOverrides={customStyles?.[BLOCKS.HEADING_2]}>
            {children}
          </Heading>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <H3Node
            node={node as Block}
            tableOfContentsFunctions={tableOfContentsFunctions}
            customStyles={customStyles?.[BLOCKS.HEADING_3]}
          >
            {children}
          </H3Node>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
          <Heading as="h4" styleOverrides={customStyles?.[BLOCKS.HEADING_4]}>
            {children}
          </Heading>
        ),
        [BLOCKS.HEADING_5]: (node, children) => (
          <Heading as="h5" styleOverrides={customStyles?.[BLOCKS.HEADING_5]}>
            {children}
          </Heading>
        ),
        [BLOCKS.OL_LIST]: (node, children) => (
          <List ordered={true} styleOverrides={customStyles?.[BLOCKS.OL_LIST]}>
            {children}
          </List>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
          <List ordered={false} styleOverrides={customStyles?.[BLOCKS.UL_LIST]}>
            {children}
          </List>
        ),
        [BLOCKS.LIST_ITEM]: (node, children) => (
          <ListElement styleOverrides={customStyles?.[BLOCKS.LIST_ITEM]}>
            {children}
          </ListElement>
        ),
        [BLOCKS.HR]: () => <Hr styleOverrides={customStyles?.[BLOCKS.HR]} />,
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const image = node.data.target as IContentfulAsset
          if (!image) return null
          return (
            <Image
              fluid={image.gatsbyImageData}
              alt={image.description}
              customStyles={customImageStyles}
            />
          )
        },
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          const data = node.data.target as BlogEmbedEntries
          return <BlogEmbedEntry data={data} />
        },
        [INLINES.EMBEDDED_ENTRY]: node => {
          const data = node.data.target as BlogEmbedInlineEntries
          return (
            <Link
              to={data?.link}
              rel={data?.rel}
              contentfulId={data?.contentful_id}
              typeName={data?.__typename}
            >
              {data?.linkText}
            </Link>
          )
        },
      },
    }),
    [customStyles, tableOfContentsFunctions],
  )

  if (!content.references) content.references = []
  return (
    <>{content && renderRichText(content as Required<RichTextData>, options)}</>
  )
}

export default RichText
