import React, {
  useState,
  useCallback,
  useContext,
  createContext,
  useRef,
} from 'react'
import { logger, LogLevel } from '../helpers/utils'

export interface ITableOfContentsItem {
  label: string
  anchor: string
}

const TableOfContentsContext = createContext<
  ITableOfContentsItem[] | undefined
>(undefined)

const TableOfContentsFunctionContext = createContext<
  ((item: ITableOfContentsItem) => void) | undefined
>(undefined)

export const TableOfContentsProvider: React.FC = ({ children }) => {
  const [tableOfContents, setTableOfContents] = useState<
    ITableOfContentsItem[]
  >([])
  const { current: tableOfContentsMap } = useRef<{ [key: string]: boolean }>({})

  const addTableOfContentsItem = useCallback(
    (item: ITableOfContentsItem) => {
      if (!tableOfContentsMap[item.anchor]) {
        tableOfContentsMap[item.anchor] = true
        setTableOfContents(curr => {
          return [...curr, item]
        })
      }
    },
    [tableOfContentsMap]
  )

  return (
    <TableOfContentsContext.Provider value={tableOfContents}>
      <TableOfContentsFunctionContext.Provider value={addTableOfContentsItem}>
        {children}
      </TableOfContentsFunctionContext.Provider>
    </TableOfContentsContext.Provider>
  )
}

export const useTableOfContents = () => {
  const context = useContext(TableOfContentsContext)
  if (!context)
    logger(
      'Table of context is being used outside of a context provider',
      LogLevel.ERROR
    )
  return context
}

export const useTableOfContentsFunctions = () =>
  useContext(TableOfContentsFunctionContext)
