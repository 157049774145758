import React from 'react'
import styled from '@emotion/styled'

import { useTheme } from '@emotion/react'
import { StyledComponentWithDefaultProps } from './StyledComponent'
import { IWithStyleOverrides } from './commonTypes'

export const Hr: React.FC<IWithStyleOverrides> = props => {
  const styles = useTheme().hr
  const Component = StyledComponentWithDefaultProps(styled.hr, styles)
  return <Component {...props} />
}
