import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import facepaint from 'facepaint'

import mq from '../styles/breakpoints'

import { Link } from './Link'
import { Image } from './Image'
import { ILink } from '../graphql/modules/link'
import { IWithCustomStyles } from './commonTypes'
import { paramsService } from '../services/paramsService'

interface IStyledProps {
  customStyles?: facepaint.BaseArg
}

const StoreBadgeContainer = styled.div<IStyledProps>(({ customStyles }) =>
  mq({
    display: 'flex',
    flexDirection: 'row',
    ...customStyles,
  }),
)
const badgeImageStyles = {
  width: ['133px', '200px', '200px'],
  height: ['40px', '60px', '60px'],
}

const customBadgeStylesLeft = {
  padding: 0,
  margin: ['0 10px 0 0 ', '0 15px 0 0', '0 15px 0 0'],
}

const customBadgeStylesRight = {
  padding: 0,
  margin: ['0 0 0 10px', '0 0 0 15px', '0 0 0 15px'],
}

export const AppStoreBadges: React.FC<IWithCustomStyles> = ({ customStyles }) => {
  const { googlePlayData, appStoreData } = useStaticQuery<{
    googlePlayData: ILink
    appStoreData: ILink
  }>(graphql`
    query appStoreBadges {
      appStoreData: contentfulLink(contentful_id: { eq: "5jTa8IBTyg0cH07VuGPYEr" }) {
        ...LinkFragment
      }
      googlePlayData: contentfulLink(contentful_id: { eq: "Wy6kRMbDEbOroYcxZomf3" }) {
        ...LinkFragment
      }
    }
  `)

  const [appStoreLinks, setAppStoreLinks] = React.useState<{
    appStoreLink: string
    googlePlayLink: string
  }>({
    appStoreLink: appStoreData.link,
    googlePlayLink: googlePlayData.link,
  })
  const { appStoreAlt, googlePlayAlt } = {
    appStoreAlt: appStoreData.alternateText,
    googlePlayAlt: googlePlayData.alternateText,
  }
  const appStoreBadgeFluidImg = appStoreData.image?.image.gatsbyImageData
  const googlePlayBadgeFluidImg = googlePlayData.image?.image.gatsbyImageData
  const { appStoreLink, googlePlayLink } = appStoreLinks

  React.useEffect(() => {
    if (appStoreLink.includes('{{mktg_site_session_id}}') || googlePlayLink.includes('{{mktg_site_session_id}}')) {
      const formattedAppStoreLink = paramsService.formatUrlWithMarketingSiteSessionId(appStoreLink)
      const formattedGooglePlayLink = paramsService.formatUrlWithMarketingSiteSessionId(googlePlayLink)
      setAppStoreLinks(() => ({ appStoreLink: formattedAppStoreLink, googlePlayLink: formattedGooglePlayLink }))
    }
  }, [appStoreLink, googlePlayLink])

  return (
    <StoreBadgeContainer customStyles={customStyles}>
      <Link
        to={appStoreLink}
        styleOverrides={customBadgeStylesLeft}
        ariaLabel={appStoreAlt}
        contentfulId={appStoreData.contentful_id}
        typeName={appStoreData.__typename}
        withoutTextStyling
      >
        {appStoreBadgeFluidImg && (
          <Image fluid={appStoreBadgeFluidImg} alt={appStoreAlt} customStyles={badgeImageStyles} />
        )}
      </Link>
      <Link
        to={googlePlayLink}
        styleOverrides={customBadgeStylesRight}
        ariaLabel={googlePlayAlt}
        contentfulId={googlePlayData.contentful_id}
        typeName={googlePlayData.__typename}
        withoutTextStyling
      >
        {googlePlayBadgeFluidImg && (
          <Image fluid={googlePlayBadgeFluidImg} alt={googlePlayAlt} customStyles={badgeImageStyles} />
        )}
      </Link>
    </StoreBadgeContainer>
  )
}

export default AppStoreBadges
